import React from "react"
import { Link as PrismicLink } from "prismic-reactjs"
import { linkResolver } from "./linkResolver"
import { Link } from "gatsby"

// const Elements = RichText.Elements //NOT EXPORTED ANYMORE?
const Elements = {
  heading1: "heading1",
  heading2: "heading2",
  heading3: "heading3",
  heading4: "heading4",
  heading5: "heading5",
  heading6: "heading6",
  paragraph: "paragraph",
  preformatted: "preformatted",
  strong: "strong",
  em: "em",
  listItem: "list-item",
  oListItem: "o-list-item",
  list: "group-list-item",
  oList: "group-o-list-item",
  image: "image",
  embed: "embed",
  hyperlink: "hyperlink",
  label: "label",
  span: "span",
}

export default function (type, element, content, children, index) {
  // Generate links to Prismic Documents as <Link> components
  if (type === Elements.hyperlink) {
    let result = ""
    const url = PrismicLink.url(element.data, linkResolver)

    if (element.data.link_type === "Document") {
      result = (
        <Link to={url} key={index}>
          {children}
        </Link>
      )
    } else {
      const target = element.data.target
        ? { target: element.data.target, rel: "noopener nofollow" }
        : {}
      result = (
        <a href={url} {...target} key={index}>
          {children}
        </a>
      )
    }
    return result
  }

  // Return null to stick with the default behavior for everything else
  return null
}
