import React, { Fragment, useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { RichText } from "prismic-reactjs"
import cn from "classnames"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"
import WavesImage from "../components/waves-image"
import startsWith from "lodash/startsWith"
import { withPreview } from "gatsby-source-prismic"
import { localizePath, redirect } from "../utils/helpers"

export const query = graphql`
  query PricingQuery($uid: String, $lang: String) {
    prismicPricing(lang: { eq: $lang }, uid: { eq: $uid }) {
      data {
        bill_monthly
        button
        bill_upfront
        description_meta
        discount
        duration_label
        duration_options {
          duration
        }
        intro {
          text
          raw
        }
        custom_pricing {
          text
          raw
        }
        job_slots_options {
          job_slot
        }
        monthly
        months
        notes {
          note
        }
        per_slot
        slots_label
        title
        title_meta
        upfront
      }
    }
  }
`

const isCustom = jobSlots => jobSlots === "∞"

const renderPrice = price => (price / 100).toFixed(2).replace(".", ",")

const renderPricePerJobSlot = (price, jobSlots) =>
  (price / 100 / jobSlots).toFixed(2).replace(".", ",")

const RenderBody = ({
  bill_monthly,
  bill_upfront,
  button,
  custom_pricing,
  discount,
  duration_label,
  duration_options,
  intro,
  job_slots_options,
  monthly,
  months,
  notes,
  per_slot,
  slots_label,
  title,
  upfront,
}) => {
  const [jobSlots, setJobSlots] = useState("3")
  const [duration, setDuration] = useState("12")
  const [isUpfront, setIsUpfront] = useState(false)
  const [plans, setPlans] = useState(null)
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window
        .fetch(
          `https://api.dev.moberries.com/api/v2/subscriptions/?limit=999&types=main`,
        )
        .then(res => res.json())
        .then(json => setPlans(json.results))
        .catch(err => console.log(err))
    }
  }, [])
  const period = isUpfront ? Number(duration) : 1

  const plan =
    plans &&
    plans.find(
      p =>
        p.billing_interval_count === period &&
        p.duration_interval_count === Number(duration) &&
        p.jobs_max === Number(jobSlots) &&
        startsWith(p.name, "MAIN"),
    )
  if (!plans) {
    return null
  }
  return (
    <Fragment>
      <section className="bg-dark py-6">
        <div className="container">
          <h1 className="text-white text-center">{title}</h1>
        </div>
      </section>
      <WavesImage page="home" />
      <section className="pt-4 pb-9">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="mb-5">
                {intro &&
                  RichText.render(intro.raw, linkResolver, htmlSerializer)}
              </div>
              <div className="bg-white mb-4 p-4 rounded shadow">
                <div className="row">
                  <div className="col-xl-7">
                    <h4>{duration_label}</h4>
                    <div className="mb-4">
                      <div
                        className="btn-group btn-group-toggle"
                        data-toggle="buttons"
                      >
                        {duration_options.map((o, i) => (
                          <label
                            key={i}
                            className={cn("btn btn-light", {
                              active: duration === o.duration,
                            })}
                          >
                            {`${o.duration} ${months}`}
                            <input
                              id={`duration${o.duration}`}
                              type="radio"
                              name="duration"
                              value={o.duration}
                              onClick={() => setDuration(o.duration)}
                            />
                          </label>
                        ))}
                      </div>
                    </div>
                    <h4>{slots_label}</h4>
                    <div className="mb-4">
                      <div
                        className="btn-group btn-group-toggle"
                        data-toggle="buttons"
                      >
                        {job_slots_options.map((o, i) => (
                          <label
                            key={i}
                            className={cn("btn btn-light d-md-inline-block", {
                              active: jobSlots === o.job_slot,
                              "d-none": o.job_slot > 5 && o.job_slot < 20,
                            })}
                          >
                            {o.job_slot}
                            <input
                              id={`slots${o.job_slot}`}
                              type="radio"
                              name="slots"
                              value={o.job_slot}
                              onClick={() => setJobSlots(o.job_slot)}
                            />
                          </label>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4 mb-md-0">
                      <h4>{upfront}</h4>
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="upfront"
                          type="checkbox"
                          value="1"
                          checked={isUpfront}
                          onChange={() => setIsUpfront(!isUpfront)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="upfront"
                        >
                          {discount}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 d-md-flex flex-column">
                    <strong>{monthly}</strong>
                    <span
                      className={cn({ "d-none": !isUpfront })}
                      id="bil-upfront"
                    >
                      {bill_upfront}
                    </span>
                    <span
                      className={cn({ "d-none": isUpfront })}
                      id="bil-monthly"
                    >
                      {bill_monthly}
                    </span>
                    <div className="h3 text-success">
                      <span id="price-month">
                        {isCustom(jobSlots) && "Custom"}
                        {plan && renderPrice(plan.price)}
                      </span>
                    </div>
                    {isCustom(jobSlots) && (
                      <small className="text-muted" id="price-custom">
                        {RichText.render(
                          custom_pricing.raw,
                          linkResolver,
                          htmlSerializer,
                        )}
                      </small>
                    )}
                    <strong>{per_slot} </strong>
                    <div className="h5 text-success">
                      <span id="price-slot">
                        {isCustom(jobSlots) && "Custom"}
                        {plan && renderPricePerJobSlot(plan.price, jobSlots)}
                      </span>
                    </div>
                    <a
                      className="cta mt-auto text-center d-block"
                      href="https://my.moberries.com/signup"
                    >
                      {button}
                    </a>
                  </div>
                </div>
              </div>
              <div className="text-center">
                {notes.map((n, i) => (
                  <Fragment key={i}>
                    <small className="text-muted">{n.note}</small>
                    <br />
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

const PricingTemplate = ({ data, pageContext, uri, location }) => {
  const { lang: locale } = pageContext
  // https://github.com/moberries/homepage/issues/139
  useEffect(() => {
    redirect(localizePath("/#pricing-section", locale))
  }, [])
  const doc = data.prismicPricing.data

  if (!doc) return null
  const {
    bill_monthly,
    bill_upfront,
    button,
    custom_pricing,
    discount,
    duration_label,
    duration_options,
    intro,
    job_slots_options,
    monthly,
    months,
    notes,
    per_slot,
    slots_label,
    title,
    upfront,
    title_meta,
    keywords_meta,
    description_meta,
    image_meta, // https://www.gatsbyjs.com/docs/reference/graphql-data-layer/schema-customization/#creating-type-definitions
  } = doc

  return (
    <Layout
      metadata={{
        title: title_meta,
        description: description_meta,
        keywords: keywords_meta,
        image: image_meta && image_meta.url,
      }}
      mainPage="companies"
      locale={locale}
      path={location && location.pathname}
    >
      <RenderBody
        bill_monthly={bill_monthly}
        bill_upfront={bill_upfront}
        button={button}
        custom_pricing={custom_pricing}
        discount={discount}
        duration_label={duration_label}
        duration_options={duration_options}
        intro={intro}
        job_slots_options={job_slots_options}
        monthly={monthly}
        months={months}
        notes={notes}
        per_slot={per_slot}
        slots_label={slots_label}
        title={title}
        upfront={upfront}
        locale={locale}
      />
    </Layout>
  )
}

export default withPreview(PricingTemplate)
